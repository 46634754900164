<template>
  <form
    action=""
    method="POST"
    @submit.prevent="onSubmit"
    @keydown="form.errors.clear($event.target.name)"
    enctype="multipart/form-data"
    class="max-w-3xl mx-auto"
  >
    <v-card class="mb-2">
      <v-card-body>
        <v-card-title class="mb-2">Poze</v-card-title>
        <div
          class="
            mt-1
            flex
            justify-center
            border-2 border-gray-300 border-dashed
            rounded-md
            p-2
          "
          @dragover.prevent
          @drop.prevent
          @drop="onFileChange"
        >
          <div class="text-center space-y-2">
            <div v-if="form.gallery.length" class="flex flex-col">
              <div class="w-full my-1 relative">
                <img :src="createUrl(form.gallery[0])" />
                <button
                  class="absolute top-0 right-0"
                  @click.prevent="removeImage(form.gallery[0])"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 bg-white opacity-70 hover:opacity-100"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div class="flex flex-row flex-wrap space-x-1">
                <img
                  v-for="(image, index) in form.gallery"
                  :key="image.name"
                  :src="createUrl(image)"
                  class="h-10 w-10"
                  @click="moveImageToStart(index)"
                />
              </div>
            </div>

            <svg
              v-else
              class="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div class="flex text-sm text-gray-600">
              <label
                for="file-upload"
                class="
                  relative
                  cursor-pointer
                  bg-white
                  rounded-md
                  font-medium
                  text-indigo-600
                  hover:text-indigo-500
                  focus-within:outline-none
                  focus-within:ring-2
                  focus-within:ring-offset-2
                  focus-within:ring-indigo-500
                "
              >
                <span>Upload a file</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  class="sr-only"
                  @change="onFileChange"
                  multiple
                />
              </label>
              <p class="pl-1">or drag and drop</p>
            </div>

            <p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
            <v-error :error="form.errors.get('gallery')" />
          </div>
        </div>
      </v-card-body>
      <v-card-footer>
        <div class="flex flex-row flex-wrap space-x-1">
          <img
            v-for="image in uploadedImages"
            :key="image.id"
            :src="image.original"
            class="h-10 w-10"
          />
        </div>
      </v-card-footer>
    </v-card>
    <v-card>
      <v-card-body>
        <!-- <div class="w-full">
          <div
            class="
              mt-1
              flex
              justify-center
              border-2 border-gray-300 border-dashed
              rounded-md
              p-2
            "
            @dragover.prevent
            @drop.prevent
            @drop="onFileChange"
          >
            <div class="text-center space-y-2">
              <div v-if="form.gallery.length" class="flex flex-col">
                <div class="w-full my-1 relative">
                  <img :src="createUrl(form.gallery[0])" />
                  <button
                    class="absolute top-0 right-0"
                    @click.prevent="removeImage(form.gallery[0])"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 bg-white opacity-70 hover:opacity-100"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <div class="flex flex-row flex-wrap space-x-1">
                  <img
                    v-for="(image, index) in form.gallery"
                    :key="image.name"
                    :src="createUrl(image)"
                    class="h-10 w-10"
                    @click="moveImageToStart(index)"
                  />
                </div>
              </div>

              <svg
                v-else
                class="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div class="flex text-sm text-gray-600">
                <label
                  for="file-upload"
                  class="
                    relative
                    cursor-pointer
                    bg-white
                    rounded-md
                    font-medium
                    text-indigo-600
                    hover:text-indigo-500
                    focus-within:outline-none
                    focus-within:ring-2
                    focus-within:ring-offset-2
                    focus-within:ring-indigo-500
                  "
                >
                  <span>Upload a file</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    class="sr-only"
                    @change="onFileChange"
                    multiple
                  />
                </label>
                <p class="pl-1">or drag and drop</p>
              </div>

              <p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
              <v-error :error="form.errors.get('gallery')" />
            </div>
          </div>
        </div> -->

        <div class="flex flex-col">
          <fieldset class="mb-4">
            <legend class="font-bold mb-2">Masina</legend>
            <v-label for="make_id"> Marca </v-label>
            <select-make
              v-model="form.make_id"
              selectId="make_id"
              class="w-full"
              required
            />
            
            <v-label class="block mt-2" for="car_id"> Model </v-label>
            <select-car
              :makeId="form.make_id"
              v-model="form.car_id"
              selectId="car_id"
              class="w-full"
            />
          </fieldset>

          <fieldset class="my-4">
            <legend class="font-bold mb-2">Piesa</legend>

            <v-label>Nume</v-label>
            <v-input type="text" v-model="form.name"></v-input>
            <v-error :error="form.errors.get('name')"></v-error>
            <autocomplete
              :options="productOptions"
              v-model="selectedProductOption"
            />
            <!-- <div class="relative mt-1">
                <ul
                  v-if="productOptions.length"
                  class="bg-white absolute rounded-md w-full border"
                >
                  <li
                    v-for="option in productOptions"
                    :key="option.id"
                    @click="selectOption(option.id)"
                    class="hover:text-gray-500 cursor-pointer border-b-2 p-2"
                  >
                    <p>{{ option.name }}</p>
                  </li>
                </ul>
              </div> -->

            <v-label class="block mt-2">Pret unitar</v-label>
            <v-input type="number" step=".01" v-model="form.price"></v-input>
            <v-error :error="form.errors.get('price')"></v-error>

            <v-label class="block mt-2"> Unitate de masura </v-label>
            <select-measurement-unit
              v-model="form.unit_id"
              selectId="unit_id"
              class="w-full"
              required
            />
            <v-error :error="form.errors.get('unit_id')"></v-error>

            <v-label class="block mt-2">Stock</v-label>
            <v-input type="number" v-model="form.stock"></v-input>
            <v-error :error="form.errors.get('stock')"></v-error>
          </fieldset>

          <fieldset class="my-4">
            <legend class="font-bold mb-2">Transport (unit)</legend>

            <v-label>Grame</v-label>
            <v-input type="number" v-model="form.weight"></v-input>
            <v-error :error="form.errors.get('weight')"></v-error>

            <v-label class="block mt-2">Lungime/pachet</v-label>
            <v-input type="number" v-model="form.length"></v-input>
            <v-error :error="form.errors.get('length')"></v-error>

            <v-label class="block mt-2">Latime/pachet</v-label>
            <v-input type="number" v-model="form.width"></v-input>
            <v-error :error="form.errors.get('width')"></v-error>

            <v-label class="block mt-2">Inaltime/pachet</v-label>
            <v-input type="number" v-model="form.height"></v-input>
            <v-error :error="form.errors.get('height')"></v-error>
          </fieldset>

          <fieldset class="my-4">
            <legend class="font-bold mb-2">Aditionale</legend>

            <v-label>Garantie (zile)</v-label>
            <v-input type="number" v-model="form.warranty"></v-input>
            <v-error :error="form.errors.get('warranty')"></v-error>

            <v-label class="block mt-2">Retur (zile) </v-label>
            <v-input type="number" v-model="form.return"></v-input>
            <v-error :error="form.errors.get('return')"></v-error>
          </fieldset>
        </div>
      </v-card-body>
      <v-card-footer class="text-right">
        <v-button :disabled="form.loading">
          <v-button-icon v-if="form.loading">
            <loader :radius="5" />
          </v-button-icon>
          Salveaza
        </v-button>
      </v-card-footer>
    </v-card>
  </form>
</template>

<script>
import _ from "lodash";
import rfqItemsQuotations from "@/api/resources/rfqItemsQuotations";
import Form from "@/core/Form";
import VButton from "@/components/VButton.vue";
import VCard from "@/components/VCard.vue";
import VInput from "@/components/VInput.vue";
import VLabel from "@/components/VLabel.vue";
import VError from "@/components/VError.vue";
import SelectMake from "@/components/shared/SelectMake.vue";
import SelectMeasurementUnit from "@/components/shared/SelectMeasurementUnit.vue";
import VCardBody from "@/components/VCardBody.vue";
import VCardFooter from "@/components/VCardFooter.vue";
import productsApi from "@/api/resources/productsApi";
import Autocomplete from "@/components/Autocomplete.vue";
import VButtonIcon from "@/components/VButtonIcon.vue";
import Loader from "@/components/Loader.vue";
import SelectCar from "./shared/SelectCar.vue";
import VCardTitle from "./VCardTitle.vue";

export default {
  components: {
    VCard,
    VLabel,
    VInput,
    VButton,
    VError,
    SelectMake,
    SelectMeasurementUnit,
    VCardBody,
    VCardFooter,
    Autocomplete,
    VButtonIcon,
    Loader,
    SelectCar,
    VCardTitle,
  },

  props: {
    rfqId: String,
    quotation: Object,
  },

  emits: ["created:quotation"],

  data() {
    return {
      form: new Form({
        weight: "",
        length: "",
        width: "",
        height: "",
        name: "",
        price: "",
        unit_id: "",
        stock: "",
        warranty: "",
        return: "",
        make_id: "",
        car_id: "",
        gallery: [],
      }),
      products: [],
      productOptions: [],
      selectedProductOption: "",
    };
  },

  computed: {
    inputName() {
      return this.form.name;
    },
  },

  watch: {
    inputName() {
      if (
        this.form.name.length > 1 &&
        this.form.name !== this.selectedProductOption
      ) {
        this.searchTerm(this.form.name);
      }
    },

    selectedProductOption() {
      const product = _.find(
        this.products,
        (element) => element.name == this.selectedProductOption
      );

      this.form.setData(product);
      this.form.setData({
        make_id: product.car.make.id,
        model_id: product.car.model.id,
        year_id: product.car.year.id,
        car_id: product.car.id,
      });
    },
  },

  methods: {
    searchTerm: _.debounce(async function (term) {
      const response = await productsApi.index({ name: term });

      this.products = response.data;
      this.productOptions = response.data.map((element) => {
        return {
          value: element.name,
          text: element.name,
        };
      });
    }, 400),

    createUrl(value) {
      return URL.createObjectURL(value);
    },

    removeImage(image) {
      let gallery = Array.from(this.form.gallery);

      this.form.gallery = gallery.filter(
        (element) => element.name !== image.name
      );
    },

    moveImageToStart(index) {
      let gallery = Array.from(this.form.gallery);

      // let indexToMove = gallery.findIndex((element) => element.name === image.name);

      let movedImage = gallery.splice(index, 1);
      gallery.splice(0, 0, movedImage[0]);

      this.form.gallery = gallery;
    },

    onSubmit() {
      this.form
        .submitService(rfqItemsQuotations, "store", { rfqItem: this.rfqId })
        .then((data) => {
          this.form.setData(data);
          this.$emit("created:quotation");
        });
    },

    onFileChange(e) {
      this.form.gallery = Array.from(e.target.files || e.dataTransfer.files);

      if (this.form.gallery.length > 3) {
        this.form.gallery.splice(2, this.form.gallery.length - 3);
      }
    },
  },

  mounted() {
    if (this.quotation) {
      this.form.setData(this.quotation);
      this.form.setData(this.quotation.product);
      this.form.setData({
        model_id: this.quotation.product.model.id,
        make_id: this.quotation.product.model.make_id,
      });
    }
  },
};
</script>

<style></style>
