<template>
  <select
    :name="selectName"
    :id="selectId"
    class="
      inline-block
      py-2
      px-3
      border border-gray-300
      bg-white
      rounded-md
      shadow-sm
      focus:outline-none focus:ring-yellow-500 focus:border-yellow-500
      sm:text-sm
    "
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  >
    <option disabled value="">{{ placehoder }}</option>
    <option v-for="option in options" :value="option.value" :key="option.value">
      {{ option.text }}
    </option>
  </select>
</template>

<script>
export default {
  components: {},
  props: {
    modelValue: [String, Number],
    selectName: String,
    selectId: String,
    options: Array,
    placehoder: { type: String, default: "Alege" },
  },
  emits: ["update:modelValue"],
};
</script>

<style></style>
