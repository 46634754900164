import {
    VENDOR_PRODUCTS, VENDOR_PRODUCTS_SHOW
} from "../endpoints";
import api from "../api";

export default {
    index(query = null) {
        let url = new URL(VENDOR_PRODUCTS);

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    show(product = null) {
        if (product === null) return;

        const url = VENDOR_PRODUCTS_SHOW.replace(":product", product);

        return api.get(url);
    },

    store(data) {
        if (data === null) return;

        // prepare the price 
        if (Object.prototype.hasOwnProperty.call(data, "price")) {
            data.price = data.price * 100;
        }

        // send it as formdata
        let formData = new FormData();
        for (let property in data) {
            formData.append(property, data[property]);
        }
        for (let i = 0; i < data.gallery.length; i++) {
            formData.append("gallery[]", data.gallery[i]);
        }

        return api.post(VENDOR_PRODUCTS, formData);
    },

    update(data, { product }) {
        if (product === null || data === null) return;

        const url = VENDOR_PRODUCTS_SHOW.replace(":product", product);

        let formData = new FormData();

        for (let property in data) {
            formData.append(property, data[property]);
        }

        for (let i = 0; i < data.gallery.length; i++) {
            formData.append("gallery[]", data.gallery[i]);
        }

        formData.append("_method", "PUT");

        return api.post(url, formData);
    }
}