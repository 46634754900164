import { VENDOR_RFQ_ITEMS, VENDOR_RFQ_ITEMS_SHOW, VENDOR_RFQ_ITEMS_QUOTATIONS } from "../endpoints";
import api from "../api";

export default {
    index(query = null) {
        let url = new URL(VENDOR_RFQ_ITEMS);

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    show(rfqItem = null) {
        if (rfqItem === null) return;

        const url = VENDOR_RFQ_ITEMS_SHOW.replace(":rfqItem", rfqItem);

        return api.get(url);
    },

    quotationsIndex(rfqItem, query) {
        if (rfqItem === null) return;

        let url = new URL(VENDOR_RFQ_ITEMS_QUOTATIONS.replace(":rfqItem", rfqItem));

        if (query !== null) {
            url.search = new URLSearchParams(query).toString();;
        }

        return api.get(url);
    }
}