<template>
  <app-layout>
    <page-header>
      Cotatiile mele
      <template #end>
        <router-link class="btn-primary" :to="{ name: 'quotations.create' }">
          <v-button-icon>
            <PlusIcon />
          </v-button-icon>
          Adauga cotatie
        </router-link>
      </template>
    </page-header>

    <page-section>
      <loader v-if="loading" />

      <template v-else>
        <template v-if="totalRows">
          <quotations-index-list
            :quotations="quotations"
            @delete:quotation="onDelete($event)"
          />
          <pagination
            @change:page="index($event)"
            :total="meta.total"
            :current_page="meta.current_page"
            :from="meta.from"
            :to="meta.to"
            :next_page_url="links.next"
            :prev_page_url="links.prev"
          />
        </template>

        <no-results v-else />
      </template>
    </page-section>
  </app-layout>
</template>

<script>
import { PlusIcon } from "@heroicons/vue/solid";
import AppLayout from "../layout/AppLayout.vue";
import rfqItemsApi from "../api/resources/rfqItemsApi";
import Pagination from "@/components/Pagination.vue";
import quotationsApi from "../api/resources/quotationsApi";
import VButtonIcon from "../components/VButtonIcon.vue";
import QuotationsIndexList from "../components/QuotationsIndexList.vue";
import Loader from "../components/Loader.vue";
import NoResults from "../components/NoResults.vue";
import PageHeader from "../components/PageHeader.vue";
import PageSection from '../components/PageSection.vue';

export default {
  components: {
    AppLayout,
    PlusIcon,
    Pagination,
    VButtonIcon,
    QuotationsIndexList,
    Loader,
    NoResults,
    PageHeader,
    PageSection,
  },

  props: ["id"],

  data() {
    return {
      quotations: [],
      meta: {},
      links: {},
      loading: false,
    };
  },

  computed: {
    totalRows() {
      return this.quotations.length;
    },
  },

  methods: {
    onDelete(id) {
      this.loading = true;
      quotationsApi
        .destroy(id)
        .then(() => {
          this.index();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    index(page = null) {
      this.loading = true;
      rfqItemsApi
        .quotationsIndex(this.id, page && { page })
        .then((data) => {
          this.quotations = data.data;
          this.meta = data.meta;
          this.links = data.links;
          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  mounted() {
    this.index();
  },
};
</script>

<style></style>
