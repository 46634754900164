<template>
  <div class="relative mt-1" v-if="show">
    <ul
      v-if="options.length"
      class="bg-white absolute rounded-md w-full border"
    >
      <li
        v-for="option in options"
        :key="option.value"
        @click="selectOption(option.value)"
        class="hover:text-gray-500 cursor-pointer border-b-2 p-2"
      >
        <p>{{ option.text }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: [String, Number],
    options: { type: Array },
  },
  emits: ["update:modelValue"],

  data() {
    return {
      show: false,
    };
  },

  watch: {
    options() {
      this.show = true;
    },
  },

  methods: {
    selectOption(value) {
      this.$emit("update:modelValue", value);
      this.show = false;
    },
  },
};
</script>

<style>
</style>