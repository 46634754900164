<template>
  <div
    class="
      px-4
      py-3
      flex
      items-center
      justify-between
      border-t border-gray-200
      sm:px-6
    "
  >
    <div class="flex-1 flex justify-between sm:hidden">
      <a
        href="#"
        class="
          relative
          inline-flex
          items-center
          px-4
          py-2
          border border-gray-300
          text-sm
          font-medium
          rounded-md
          text-gray-700
          bg-white
          hover:bg-gray-50
        "
        :disabled="!prev_page_url"
        @click="prevPage"
      >
        Inapoi
      </a>
      <a
        href="#"
        class="
          ml-3
          relative
          inline-flex
          items-center
          px-4
          py-2
          border border-gray-300
          text-sm
          font-medium
          rounded-md
          text-gray-700
          bg-white
          hover:bg-gray-50
        "
        :disabled="!next_page_url"
        @click="nextPage"
      >
        Inainte
      </a>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          Se afiseaza
          {{ " " }}
          <span class="font-medium">{{ from }} </span>
          {{ " " }}
          pana la
          {{ " " }}
          <span class="font-medium">{{ to }}</span>
          {{ " " }}
          din
          {{ " " }}
          <span class="font-medium">{{ total }}</span>
          {{ " " }}
          rezultate
        </p>
      </div>
      <div>
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <button
            class="
              relative
              inline-flex
              items-center
              px-2
              py-2
              mx-2
              rounded-md
              border border-gray-300
              bg-white
              text-sm
              font-medium
              text-gray-500
              hover:bg-gray-50
            "
            :disabled="!prev_page_url"
            @click="prevPage"
          >
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
            <span class="mr-2">Inapoi</span>
          </button>
          <button
            href="#"
            class="
              relative
              inline-flex
              items-center
              px-2
              py-2
              mx-2
              rounded-md
              border border-gray-300
              bg-white
              text-sm
              font-medium
              text-gray-500
              hover:bg-gray-50
            "
            :disabled="!next_page_url"
            @click="nextPage"
          >
            <span class="ml-2">Inainte</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },

  props: {
    total: { type: [String, Number] },
    current_page: { type: [String, Number] },
    from: { type: [String, Number] },
    to: { type: [String, Number] },
    next_page_url: { type: String },
    prev_page_url: { type: String },
  },

  emits: ["change:page"],

  methods: {
    nextPage() {
      this.$emit("change:page", this.current_page + 1);
    },

    prevPage() {
      this.$emit("change:page", this.current_page - 1);
    },
  },
};
</script>
