<template>
  <button
    class="
      btn-danger
    "
  >
    <slot></slot>
  </button>
</template>

<script>
export default {};
</script>

<style></style>
