<template>
  <v-card class="md:flex">
    <section class="md:w-1/3 flex flex-col text-gray-600 p-4">
      <div
        class="
          flex-shrink-0
          w-auto
          h-60
          border border-gray-200
          rounded
          overflow-hidden
        "
      >
        <img
          :src="
            quotation.product.images.length
              ? featuredImage
              : 'https://via.placeholder.com/500'
          "
          alt=""
          class="w-full h-full object-center object-cover"
        />
      </div>

      <!-- <div class="flex overflow-x-auto" v-if="quotation.product.images.length"> -->
      <div class="flex overflow-x-auto space-x-2 mt-2">
        <img
          v-for="(image, index) in quotation.product.images"
          :key="image.name"
          :src="image.original"
          class="h-20 w-20"
          @click="featuredImageIndex = index"
        />
      </div>
    </section>
    <section class="w-full">
      <v-card-header>
        <v-card-title> {{ quotation.name }} </v-card-title>
        <p class="font-light text-sm">
          Pret:
          <span class="font-medium">
            {{ formatPrice(quotation.price) }}
          </span>
        </p>
        <template #actions>
          <v-button-danger @click="$emit('delete:quotation', quotation.id)">
            Sterge
          </v-button-danger>
        </template>
      </v-card-header>
      <v-card-body>
        <v-card-body-title>Masina</v-card-body-title>
        <ul class="list-disc list-inside text-sm text-gray-500">
          <li>
            Marca:
            <span class="ml-1"> {{ quotation.product.car.make.name }}</span>
          </li>
          <li>
            Model:
            <span class="ml-1"> {{ quotation.product.car.name }}</span>
          </li>
        </ul>

        <v-card-body-title class="mt-4">Transport (unit)</v-card-body-title>
        <ul class="flex flex-row space-x-6 mt-1 text-sm text-gray-500">
          <li>
            Grame:
            <span class="ml-1"> {{ quotation.product.weight }}</span>
          </li>
          <li>
            Lungime/pachet:
            <span class="ml-1"> {{ quotation.product.length }}</span>
          </li>
          <li>
            Latime/pachet:
            <span class="ml-1"> {{ quotation.product.width }}</span>
          </li>
          <li>
            Inaltime/pachet:
            <span class="ml-1"> {{ quotation.product.height }}</span>
          </li>
        </ul>

        <v-card-body-title class="mt-4">Aditionale</v-card-body-title>
        <ul class="flex flex-row space-x-6 mt-1 text-sm text-gray-500">
          <li>
            Garantie (zile):
            <span class="ml-1"> {{ quotation.warranty }}</span>
          </li>
          <li>
            Retur (zile):
            <span class="ml-1"> {{ quotation.return }}</span>
          </li>
          <li>
            Stock:
            <span class="ml-1"> {{ quotation.product.stock }}</span>
          </li>
        </ul>
      </v-card-body>
    </section>
  </v-card>
</template>

<script>
import { priceFormatter } from "@/helpers";

import VCard from "./VCard.vue";
import VCardBody from "./VCardBody.vue";
import VCardBodyTitle from "./VCardBodyTitle.vue";
import VCardHeader from "./VCardHeader.vue";
import VCardTitle from "./VCardTitle.vue";
import VButtonDanger from "./VButtonDanger.vue";

export default {
  components: {
    VCard,
    VCardHeader,
    VCardBody,
    VCardBodyTitle,
    VCardTitle,
    VButtonDanger,
  },

  props: {
    quotation: Object,
  },

  data() {
    return {
      featuredImageIndex: 0,
    };
  },

  computed: {
    featuredImage() {
      return this.quotation.product.images[this.featuredImageIndex].original;
    },
  },

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },
  },
};
</script>

<style></style>
