<template>
  <app-layout>
    <page-header> Adauga oferta </page-header>

    <page-section>
      <quotations-create-form :rfq-id="id" @created:quotation="redirect()" />
    </page-section>
  </app-layout>
</template>

<script>
import PageHeader from "../components/PageHeader.vue";
import PageSection from "../components/PageSection.vue";
import QuotationsCreateForm from "../components/QuotationsCreateForm.vue";
import AppLayout from "../layout/AppLayout.vue";

export default {
  components: {
    AppLayout,
    QuotationsCreateForm,
    PageHeader,
    PageSection,
  },

  props: ["id"],

  methods: {
    redirect() {
      this.$router.push({ name: "quotations.index", params: { id: this.id } });
    },
  },
};
</script>

<style></style>
