<template>
  <quotation-index-item
    v-for="quotation in quotations"
    :key="quotation.id"
    class="mb-4"
    :quotation="quotation"
    @delete:quotation="$emit('delete:quotation', $event)"
  />
</template>

<script>
import QuotationIndexItem from "./QuotationIndexItem.vue";
export default {
  components: { QuotationIndexItem },
  props: {
    quotations: {
      type: Array,
    },
  },
  emits: ["delete:quotation"],
};
</script>

<style></style>
