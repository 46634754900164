import { VENDOR_QUOTATIONS_SHOW } from "../endpoints";
import api from "../api";

export default {
    destroy(quotation = null) {
        if (quotation === null) return;

        const url = VENDOR_QUOTATIONS_SHOW.replace(":quotation", quotation);

        return api.delete(url);
    }
}