<template>
  <header class="flex justify-between border-b-2 items-center p-4">
    <div class="flex-1">
      <slot></slot>
    </div>
    <div class="max:w-1/2 text-right">
      <slot name="actions"></slot>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style></style>
