import { VENDOR_RFQ_ITEMS_QUOTATIONS } from "../endpoints";
import api from "../api";

export default {
    store(data, { rfqItem }) {
        if (rfqItem === null || data === null) return;

        const url = VENDOR_RFQ_ITEMS_QUOTATIONS.replace(":rfqItem", rfqItem);

        //prepare the price 
        if (Object.prototype.hasOwnProperty.call(data, 'price')) {
            data.price = data.price * 100;
        }

        // send it as formdata
        let formData = new FormData();
        for (let property in data) {
            formData.append(property, data[property]);
        }
        for (let i = 0; i < data.gallery.length; i++) {
            formData.append("gallery[]", data.gallery[i]);
        }

        return api.post(url, formData);
    },
}